<template>
  <div class="teacher">
    <div>
      <el-button type="primary" @click="onClick(1)">已驳回的学生名单</el-button>
    </div>
    <div>
      <el-button type="primary" @click="onClick(2)">待教学老师审批学生名单</el-button>
    </div>
    <div>
      <el-button type="primary" @click="onClick(3)">待班主任审批学生名单</el-button>
    </div>
    <div>
      <el-button type="primary" @click="onClick(4)">待教务处审批学生名单</el-button>
    </div>
    <div>
      <el-button type="primary" @click="onClick(5)">待校长审批学生名单</el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import tools from "@/utils/tools";
export default {
	_config:{"route":{"path":"index","meta":{"title":"操作"}}},
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    onClick(type){
      if(type === 1){
        this.$_axios2.post('api/student/excel-exam/reject').then(res => {
          tools.download('已驳回的学生名单', res.data)
        })
      }
      if(type === 2){
        this.$_axios2.post('api/student/excel-exam/teaching').then(res => {
          tools.download('待教学老师审批学生名单', res.data)
        })
      }
      if(type === 3){
        this.$_axios2.post('api/student/excel-exam/pending', {status: 2}).then(res => {
          tools.download('待班主任审批学生名单', res.data)
        })
      }
      if(type === 4){
        this.$_axios2.post('api/student/excel-exam/pending', {status: 3}).then(res => {
          tools.download('待教务处审批学生名单', res.data)
        })
      }
      if(type === 5){
        this.$_axios2.post('api/student/excel-exam/pending', {status: 4}).then(res => {
          tools.download('待校长审批学生名单', res.data)
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.teacher{
  padding: 60rem 0 0 40rem;
  &>div{
    margin-bottom: 20rem;
  }
}
</style>
